<template>
  <div>
    <top-nav></top-nav>
    <h1 class="text-white mt-5">Sorry, page not found.</h1>
  </div>
</template>


<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav
  }
};
</script>