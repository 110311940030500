<template>
  <div>
    <b-modal
      id="add-fish-modal"
      title="ADD FISH"
      hide-footer
      button-size="sm"
      ok-variant="success"
      cancel-variant="secondary"
      @show="resetKeypad"
      footer-class="mt-0 pt-0"
    >
      <b-container>
        <b-row
          align-h="center"
          class="mb-1"
        >
          <b-form-radio-group
            id="btn-radios-2"
            v-model="selectedEntryType"
            :options="options"
            button-variant="outline-success"
            size="md"
            name="radio-btn-outline"
            buttons
            @change="resetKeypad"
          ></b-form-radio-group>
        </b-row>
        <p class="mb-0">WEIGHT</p>
        <b-row
          class="ml-1"
          align-v="end"
          align-h="between"
        >
          <p
            id="weightValue"
            class="addFish-weightAmountText mt-0"
          >
            {{ weight }}
          </p>
          <div v-if="startValidating">
            <div v-if="weightStatus">
              <b-icon
                id="validWeight"
                class="addFish-weightStatus"
                icon="hand-thumbs-up"
                variant="success"
              ></b-icon>
            </div>
            <div v-else-if="!weightStatus">
              <b-icon
                id="invalidWeight"
                class="addFish-weightStatus"
                icon="exclamation-circle"
                variant="danger"
              ></b-icon>
            </div>
          </div>
        </b-row>
        <hr class="addFish-inputLine mt-0" />
        <div v-if="startValidating">
          <b-row v-if="!weightStatus">
            <p class="addFish-errorMessage">
              Value must be greater than zero and less than 100.
            </p>
          </b-row>
        </div>
        <div v-if="this.selectedEntryType == 'pounds'">
          <!-- Number Keypad -->
          <b-row align-h="center">
            <table class="md-12-col">
              <tr>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('1')"
                    >1</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('2')"
                    >2</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('3')"
                    >3</b-button
                  >
                </td>
              </tr>
              <tr>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('4')"
                    >4</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('5')"
                    >5</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('6')"
                    >6</b-button
                  >
                </td>
              </tr>
              <tr>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('7')"
                    >7</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('8')"
                    >8</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('9')"
                    >9</b-button
                  >
                </td>
              </tr>
              <tr>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('.')"
                    >.</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-success"
                    squared
                    @click="updateWeight('0')"
                    >0</b-button
                  >
                </td>
                <td class="addFish-td">
                  <b-button
                    class="addFish-keypadButton"
                    size="lg"
                    variant="outline-primary"
                    squared
                    @click="backspace()"
                  >
                    <b-icon icon="backspace"></b-icon>
                  </b-button>
                </td>
              </tr>
            </table>
          </b-row>
        </div>
        <div v-else>
          <b-row>
            <b-col>
              <p class="addFish-measurementNames">POUNDS</p>
              <b-form-select
                v-model="selectedPounds"
                :options="poundsOptions"
                @change="updateWeightPoundsOunces"
              ></b-form-select>
            </b-col>
            <b-col>
              <p class="addFish-measurementNames">OZ</p>
              <b-form-select
                v-model="selectedOunces"
                :options="ouncesOptions"
                @change="updateWeightPoundsOunces"
              ></b-form-select>
            </b-col>
            <b-col>
              <p class="addFish-measurementNames">FRACTION/OZ</p>
              <b-form-select
                v-model="selectedOunceFractions"
                :options="ounceFractionOptions"
                @change="updateWeightPoundsOunces"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <p class="addFish-measurementNames mt-2 ml-3">
            *All values will be rounded to 1/100 ounce.
          </p>
        </b-row>
        <b-button class="mt-3" size="lg" :disabled=!weightStatus variant="success" block @click="handleOk">ADD FISH</b-button>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { generateBasicId } from '../js/utilities.js';
export default {
  name: 'AddFish',
  props: ['defaultWeight'],
  data() {
    return {
      startValidating: null,
      weight: this.defaultWeight,
      showKeypad: false,
      selectedEntryType: localStorage.addFishEntryType,
      selectedPounds: null,
      selectedOunces: null,
      selectedOunceFractions: null,
      options: [
        { text: 'POUNDS', value: 'pounds' },
        { text: 'POUNDS/OZ', value: 'pounds-oz' },
      ],
      poundsOptions: [
        { value: null, text: '-' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
        { value: 11, text: '11' },
        { value: 12, text: '12' },
        { value: 13, text: '13' },
        { value: 14, text: '14' },
        { value: 15, text: '15' },
      ],
      ouncesOptions: [
        { value: null, text: '-' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
        { value: 11, text: '11' },
        { value: 12, text: '12' },
        { value: 13, text: '13' },
        { value: 14, text: '14' },
        { value: 15, text: '15' },
      ],
      ounceFractionOptions: [
        { value: null, text: '-' },
        { value: 0.125, text: '1/8' },
        { value: 0.25, text: '1/4' },
        { value: 0.375, text: '3/8' },
        { value: 0.5, text: '1/2' },
        { value: 0.625, text: '5/8' },
        { value: 0.75, text: '3/4' },
        { value: 0.875, text: '7/8' },
      ],
    };
  },
  computed: {
    weightStatus() {
      if (this.startValidating) {
        return this.isWeightValid();
      } else {
        return null;
      }
    },
    eventID() {
      return this.$store.getters['getEventID'];
    },
  },
  methods: {
    resetKeypad() {
      this.weight = '0';
      this.selectedPounds = null;
      this.selectedOunces = null;
      this.selectedOunceFractions = null;
      this.startValidating = false;
    },
    updateWeight(value) {
      // if current weight is 0 and a 0 is entered, do nothing.
      if (Number(this.weight) == 0 && value == '0') {
        this.startValidating = true;
        return;
      }
      // if a decimal place already exists and another decimal place is entered, do nothing
      if (this.weightAlreadyHasDecimal() && value == '.') {
        this.startValidating = true;
        return;
      }
      // if the current value is zero and a decimal place is entered allow it to accomodate .99
      if (Number(this.weight) == 0 && value == '.') {
        this.weight = this.weight.concat(value);
        this.startValidating = true;
        return;
      }
      // if current value is 0, clear out and enter the value
      if (this.weight == '0') {
        this.weight = value;
        this.startValidating = true;
        return;
      }
      //normal value entry
      this.weight = this.weight.concat(value);
      this.startValidating = true;
    },
    backspace() {
      this.weight = this.weight.slice(0, -1); // strip off the last character

      if (
        Number(this.weight) == 0 ||
        this.weight == '' ||
        this.weight == null
      ) {
        this.weight = '0';
      }
    },
    weightAlreadyHasDecimal() {
      return this.weight.includes('.', 0) ? true : false;
    },
    updateWeightPoundsOunces() {
      let pounds = 0;
      let ounces = 0;
      let ouncesAndFractions = 0;

      this.startValidating = true;

      if (this.selectedPounds == '-') {
        pounds = 0;
      } else {
        pounds = this.selectedPounds;
      }

      if (this.selectedOunces == '-') {
        ounces = 0;
      } else {
        ounces = this.selectedOunces;
      }

      if (this.selectedOunceFractions == '-') {
        ouncesAndFractions = ounces / 16;
      } else {
        ouncesAndFractions = ounces / 16 + this.selectedOunceFractions * 0.0625;
      }

      this.weight = pounds + ouncesAndFractions;
    },
    isWeightValid() {
      // if the input is empty there is nothing to validate
      if (this.weight == '') {
        return null;
      }
      // zero is an invalid entry
      if (this.weight == 0) {
        return false;
      }
      // value over 100 is invalid
      if (this.weight > 100) {
        return false;
      }

      return true; // all is good
    },
    handleOk(bvModalEvent) {
      if (!this.weightStatus) {
        bvModalEvent.preventDefault();
      } else {
        this.$bvModal.hide('add-fish-modal');
        this.addFish();

        //remember the entry type for the next time.
        localStorage.addFishEntryType = this.selectedEntryType;
      }
    },
    addFish() {
      let num = Number(this.weight);
      let fishCatch = {
        eventID: this.eventID,
        id: generateBasicId(16),
        timeStamp: Date.now(),
        name: localStorage.activeUser,
        time: moment().format('h:mm a'),
        weight: num.toFixed(2),
      }
      this.$store.dispatch('addFish', fishCatch);
    }
  }
};
</script>
<style>
.addFish-weightAmountText {
  font-family: var(--leaderboard-font);
  font-size: 36px;
  margin: 0px 0px 0px 0px;
}

.addFish-weightStatus {
  width: 30px;
  height: 40px;
  margin: 0px 10px 10px 0px;
}

.addFish-inputLine {
  border-top: 2px solid #bbb;
  color: red;
  margin-bottom: 20px;
}

.addFish-errorMessage {
  color: #f36262;
  margin: -10px 0px 10px 20px;
}

.addFish-keypadButton {
  font-family: var(--leaderboard-font);
  font-size: 24px;
  height: 50px;
  width: 80px;
  touch-action: manipulation;
}

.addFish-measurementNames {
  font-family: var(--leaderboard-font);
  font-size: 0.8em;
  font-weight: 200;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.addFish-td {
  padding: 1px;
  margin: 1px;
}
</style>