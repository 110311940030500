<template>
  <b-modal
    id="update-weigh-in-team-modal"
    title="Update Team"
    hide-footer
    @shown.bs.modal="loadTeamData()"
  >
  <b-row class="d-flex justify-content-end">
    <b-button size="md" class="mb-2 mr-2" variant="success" @click="deleteTeam()"  >
      DELETE TEAM<b-icon icon="trash" aria-hidden="true"></b-icon>
    </b-button>
  </b-row>
    <b-form-group
      label="Team Name:"
      label-for="teamName"
    >
      <b-form-input
        id="teamName"
        v-model="teamName"
        placeholder="Enter team name"
        required
        :formatter="formatter"
        :state="teamNameState"
        aria-describedby="teamName-feedback"
      >
    </b-form-input>
      <b-form-invalid-feedback id="teamName-feedback">
        {{ this.teamNameErrorMessage }}
      </b-form-invalid-feedback>
      
    </b-form-group>

    <b-form-group
      label="Team Number (optional):"
      label-for="teamNumber"
    >
      <b-form-input
        id="teamNumber"
        v-model="teamNumber"
        type="number"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label="Number Of Fish:"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="btn-radios-2"
        v-model="numberOfFish"
        :options="options"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="lg"
        name="radio-btn-outline"
        buttons
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      label="Total Weight (optional):"
      label-for="totalWeight"
    >
      <b-form-input
        id="totalWeight"
        v-model="totalWeight"
        required
        type="number"
        aria-describedby="totalWeight-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="totalWeight-feedback">
        {{ this.totalWeightErrorMessage }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Big Fish Weight (optional):"
      label-for="bigFishWeight"
    >
      <b-form-input
        id="bigFishWeight"
        v-model="bigFishWeight"
        required
        type="number"
        aria-describedby="bigFishWeight-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="bigFishWeight-feedback">
        {{ this.bigFishWeightErrorMessage }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-button
      class="mt-3"
      size="lg"
      variant="success"
      block
      @click="handleOk"
    >
      UPDATE TEAM
    </b-button>
  </b-modal>
</template>
<script>
import { generateBasicId } from '../js/utilities.js';
export default {
  name: 'WeighInUpdateTeam',
  props: ['teamData'],
  data() {
    return {
      teamName: '',
      teamNameOriginal: '',
      teamNumber: '',
      numberOfFish: 0,
      bigFishWeight: 0,
      totalWeight: 0,
      teamNameErrorMessage: '',
      totalWeightErrorMessage: '',
      bigFishWeightErrorMessage: '',
      minChars: 2,
      maxChars: 24,
      options: [
        { text: '0', value: 0 },
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      deleteTeamStatus: ''
    };
  },
  computed: {
    weighInTeams() {
      return this.$store.getters['getAllWeighInTeams'];
    },
    teamInfo(teamName) {
      teams = this.weighInTeams.filter((team) => team.name === teamName);
      return teams[0];
    },
    teamNameState() {
      return this.validateTeamName();
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase();
    },
    loadTeamData() {
      this.teamName = this.teamData.name;
      this.teamNameOriginal = this.teamData.name;
      this.numberOfFish = this.teamData.numberOfFish;

      this.teamNumber = this.teamData.teamNumber == 0 ? '' : this.teamData.teamNumber;
      this.bigFishWeight = this.teamData.bigFishWeight == 0 ? '' : this.teamData.bigFishWeight;
      this.totalWeight = this.teamData.totalWeight == 0 ? '' : this.teamData.totalWeight;
    },
    resetUpdateTeamModal() {
      console.log('resetting modal');
      this.teamName = '';
      this.teamNameOriginal = '';
      this.teamNumber = '';
      this.numberOfFish = '0';
      this.bigFishWeight = 0.00;
      this.totalWeight = 0.00;
    },
    validateTeamName() {
      console.log('CHECKING NAME: ' + this.teamName);

      // CHECK IF A NAME WAS ENTERED
      if (this.teamName == '') {
        console.log('no name');
        this.teamNameErrorMessage = 'Please enter a team name.';
        return false;
      }

      // MAKE SURE NAME DOES NOT HAVE INVALID CHARACTERS
      if (!this.teamName.match(/^[A-Za-z0-9&\-/+ ]+$/)) {
        console.log('bad chars');
        this.teamNameErrorMessage = 'Team name has invalid characters.';
        return false;
      }

      // MAKE SURE NAME IS THE RIGHT LENGTH 
      if (this.teamName.length < this.minChars || this.teamName.length > this.maxChars) {
        console.log('wrong num chars');
        this.teamNameErrorMessage = `Team names should be between ${this.minChars} and ${this.maxChars} characters.`;
        return false;
      }

      //MAKE SURE THE NAME IS NOT A DUPLCIATE ONLY IF THE NAME WAS MODIFIED
      if (this.teamName !== this.teamNameOriginal) {
        if (this.weighInTeams.filter(e => e.name === this.teamName).length > 0) {
          console.log('duplicate name');
          this.teamNameErrorMessage = 'Duplicate team name.';
          return false;
        }
      }
      return true;
    },
    handleOk(bvModalEvent) {
      console.log('Status: ' + this.teamNameState)
      if (this.teamNameState) {
        console.log('Team update is OK');
        this.$bvModal.hide('update-weigh-in-team-modal');
        let updatedTeam = {
          eventID: localStorage.id,
          id: this.teamData.id,
          name: this.teamName,
          teamNumber: Number(this.teamNumber),
          totalWeight: Number(this.totalWeight).toFixed(2),
          bigFishWeight: Number(this.bigFishWeight).toFixed(2),
          numberOfFish: this.numberOfFish,
          place: ''
        };
        this.$store.dispatch('updateWeighInTeam', updatedTeam);
        this.resetUpdateTeamModal();
      } else {
        console.log('Team update is NO BUENO');
        bvModalEvent.preventDefault();
      }
    },
    deleteTeam() {
      this.deleteTeamStatus = ''
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete team ${this.teamName}?`)
        .then(value => {
          this.deleteTeamStatus = value

          if (this.deleteTeamStatus) {
            this.$store.dispatch('deleteWeighInTeam', {eventID: this.teamData.eventID, id: this.teamData.id});
            this.$bvModal.hide('update-weigh-in-team-modal');
          }
        })
        .catch(err => {
          // An error occurred
        })
    }
  },
};
</script>
