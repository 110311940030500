<template>
  <div>
    <div
      v-if="allCatches.length"
      class="mt-3"
    >
      <b-card
        v-for="fish in allCatches"
        id="catchLog-card"
        :key="fish.id"
        class="mb-2 pl-2 pt-1 text-left"
        :title="fish.time"
        no-body
      >
        <p class="liveScorer-catchLogText mb-0">
          {{ fish.time }}
          <b-icon
            v-if="fish.weight == bigFish.weight"
            icon="trophy-fill"
            class="liveScorer-bigFish"
          ></b-icon>
        </p>
        <p class="text-left liveScorer-catchLogText pt-0 pb-0 mb-1">
          <strong>{{ fish.name }}</strong> caught a <strong>{{ fish.weight }}</strong> pound fish.
        </p>
      </b-card>
    </div>
    <div v-else
      class="mt-3"
    >
      <p>No fish caught in the event.</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CatchLog',
  data() {
    return {};
  },
  computed: {
    allCatches() {
      let catches = this.$store.getters['getAllCatches'];
      catches.sort(function (a, b) {
        return b.timeStamp - a.timeStamp;
      });
      return catches;
    },
    bigFish() {
      return this.$store.getters['getBigFish'];
    }
  }
};
</script>