<template>
  <div>
    <top-nav></top-nav>
    <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <h2 class="pageHeaderText mt-3 mb-3">ABOUT US</h2>

    </div>
  </div>

</template>

<script>
import TopNav from '../components/TopNav.vue';
import BuyMeACoffee from '../components/BuyMeACoffee.vue';
export default {
  components: {
    TopNav,
    BuyMeACoffee
  },
  computed:  {
    currentENV() {
      return process.env.NODE_ENV;
    },
    isMobile() {
      // console.log(screen.width);
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }

  },
  methods: {
    
  }
};
</script>