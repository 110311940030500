import moment from 'moment';

function generateEventId(length) {
  var result = '';
  var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function generateBasicId(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function sanitizeToday() {
  let date0 = new Date();
  let date1 = new Date(
    date0.getTime() + Math.abs(date0.getTimezoneOffset() * 60000)
  );
  return moment(date1).format('YYYY-MM-DD');
}

function sanitizeDate(dateToSanitize) {
  let date0 = new Date(dateToSanitize);
  let date1 = new Date(
    date0.getTime() + Math.abs(date0.getTimezoneOffset() * 60000)
  );
  return moment(date1).format('YYYY-MM-DD');
}

function beautifyDate(dateToBeautify) {
  let prettyDate = new Date(dateToBeautify);
  prettyDate = new Date(
    prettyDate.getTime() + Math.abs(prettyDate.getTimezoneOffset() * 60000)
  );
  return moment(prettyDate).format('ddd, MMM DD, YYYY');
}

function beautifyDateForScoreboard(dateToBeautify) {
  let prettyDate = new Date(dateToBeautify);
  prettyDate = new Date(
    prettyDate.getTime() + Math.abs(prettyDate.getTimezoneOffset() * 60000)
  );
  return moment(prettyDate).format('MMM DD, YYYY');
}

export {
  generateEventId,
  generateBasicId,
  sanitizeToday,
  sanitizeDate,
  beautifyDate,
  beautifyDateForScoreboard,
};
