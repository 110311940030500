import firebase from '@/firebase'
import Vue from 'vue';
import Vuex from 'vuex';

import {
  collection,
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  onSnapshot,
  deleteDoc,
  query,
  getCountFromServer,
  where
} from 'firebase/firestore';
import {
  getTeamResults,
  getBigFishOfEvent,
  calculateWeightBehindFirstPlace,
  sortStandings,
  sortWeighInTeams,
  identifyWeighInBigFish
} from '../js/standings.js';


//init services
const db = getFirestore();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    eventExists: '',
    eventType: '',
    adminID: '',
    eventID: '',
    eventDate: '',
    scoringSystem: '',
    email: '',
    bodyOfWater: '',
    teams: [],
    catches: [],
    standings: [],
    bigFish: '',
    activeTeam: '',
    mostRecentFish: '',
    weighInTeams: [],
    eventQueryInfo: {}
  },
  actions: {
    // TOURNAMENT SPECIFIC ACTIONS
    addTournament({ commit }, payload) {
      setDoc(doc(db, 'Events', payload.eventID), {
        createdDate: payload.createdDate,
        eventType: payload.eventType,
        adminID: payload.adminID,
        eventID: payload.eventID,
        email: payload.email,
        eventDate: payload.eventDate,
        scoringSystem: payload.scoringSystem,
        bodyOfWater: payload.bodyOfWater,
        env: payload.env
      })
        .then(
          //add the teams to their own collection
          payload.teams.forEach((team) => {
            setDoc(
              doc(db, 'Events/' + payload.eventID + '/Teams/' + team.id),
              {
                ...team,
                eventID: payload.eventID,
              }
            );
          }),
          commit('LOAD_TOURNAMENT', payload),
          commit('LOAD_TEAMS', payload.teams)
        )
        .catch((error) => {
          console.log('ERROR ADDING TOURNAMENT');
          console.log(error);
        });
    },
    queryEventInfo({ commit }, payload) {

      // Here is what we are trying to find out..
      // Was the code entered an Admin Code or an Event Code?
      // Was the code entered from a tournament or weigh in?
      
      // We need to return an object with:
      // { eventExists: true/false,
      //   isAdmin: true/false,
      //   eventType: tournament/weighIn
      //  }

      // For this action, we'll pass in a code. We don't know if it is
      // and Admin or Event Code.
      // search for the event as if the code was an Event Code
      const docRef = doc(db, 'Events', payload.eventID);
      getDoc(docRef).then((doc) => {
        console.log(doc.data())
        if (doc.data() != null) {
          //event exists
          let event = doc.data();

          //check if admin code is valid if entered
          let validAdminCode = '';
          if (payload.adminID != '') {
            if (payload.adminID == event.adminID ) {
              validAdminCode = true;
            } else {
              //the code entered was an event code
              validAdminCode = false;
            }
          }
          //send the info to the store
          commit('SET_EVENT_QUERY_INFO',
            { eventExists: true,
              isAdmin: validAdminCode,
              eventType: event.eventType,
              eventDate: event.eventDate
            })
        } else {
          commit('SET_EVENT_QUERY_INFO', { eventExists: false })
        }
      });
    },
    doesTournamentExist({ commit }, payload) {
      console.log('EVENT: ' + payload)
      const docRef = doc(db, 'Events', payload);
      getDoc(docRef).then((doc) => {
        console.log(doc.data())

        if (doc.data() == null) {
          console.log('CHECKING IF TOURNAMENT EXISTS')
          commit('SET_EVENT_EXISTS_STATUS', 'INVALID');
        } else {
          console.log('should be good')
          let event = doc.data();
          commit('SET_EVENT_EXISTS_STATUS', `FOUND - ${event.eventType}`);
          commit('LOAD_TOURNAMENT', doc.data());
        }
      });
    },
    loadTournament({ commit }, payload) {
      // Load core tournament info from DB
      const docRef = doc(db, 'Events', payload);
      getDoc(docRef).then((doc) => {
        if (doc.data() == null) {
          commit('SET_EVENT_EXISTS_STATUS', 'INVALID');
        } else {
          commit('SET_EVENT_EXISTS_STATUS', 'FOUND');
          commit('LOAD_TOURNAMENT', doc.data());

          // Load the teams from DB
          getDocs(collection(db, 'Events/' + payload + '/Teams')).then(
            (snapshot) => {
              const teams = [];
              snapshot.docs.forEach((doc) => {
                teams.push(doc.data());
              });
              commit('LOAD_TEAMS', teams),
                this.dispatch('eventTeamsListener', payload);
            }
          );
          // Load the catches from DB
          // Update the standings in the store
          getDocs(collection(db, 'Events/' + payload + '/Catches')).then(
            (snapshot) => {
              const catches = [];
              snapshot.docs.forEach((doc) => {
                catches.push(doc.data());
              });
              commit('LOAD_CATCHES', catches),
              commit('UPDATE_STANDINGS'),
              this.dispatch('allCatchesListener', payload);
            }
          );
        }
      });
    },
    // TEAM SPECIFIC ACTIONS
    loadTeams({ commit }, payload) {
      getDocs(collection(db, 'Events/' + payload + '/Teams')).then(
        (snapshot) => {
          const teams = [];
          snapshot.docs.forEach((doc) => {
            teams.push(doc.data());
          });
          commit('LOAD_TEAMS', teams);
        }
      );
    },
    updateTeam({ commit }, payload) {
      // payload should be a team object
      setDoc(doc(db, 'Events', payload.eventID + '/Team/' + payload.id), {
        ...payload,
      }).then(() => {
        this.dispatch('loadTeams', payload.eventID);
      });
    },
    // FISH CATCH ACTIONS
    addFish({ commit, getters }, payload) {
      setDoc(doc(db, 'Events/' + payload.eventID + '/Catches/' + payload.id), {
        ...payload,
      });
      commit('MOST_RECENT_FISH', payload);
    },
    deleteFish({ commit }, payload) {
      deleteDoc(doc(db, 'Events/' + payload.eventID + '/Catches', payload.id));
      commit('EMPTY_MUTATION');
    },
    allCatchesListener({ commit }, payload) {
      //listen to the catches collection for any new or removed catch documents
      const q = query(collection(db, 'Events/' + payload + '/Catches'));
      onSnapshot(q, (querySnapshot) => {
        console.log('LISTENER - CHANGE DETECTED TO CATCHES');
        const catches = [];
        querySnapshot.forEach((doc) => {
          catches.push(doc.data());
        });
        commit('LOAD_CATCHES', catches);
        commit('UPDATE_STANDINGS');
      });
    },
    eventTeamsListener({ commit }, payload) {
      //listen to the catches collection for any new or removed catch documents
      const q = query(collection(db, 'Events/' + payload + '/Teams'));
      onSnapshot(q, (querySnapshot) => {
        console.log('LISTENER - CHANGE DETECTED TO TEAMS');
        const teams = [];
        querySnapshot.forEach((doc) => {
          teams.push(doc.data());
        });
        commit('LOAD_TEAMS', teams);
      });
    },

    // ===========================================================
    // BELOW ACTIONS ARE FOR THE LIVE WEIGH IN FEATURE
    // ===========================================================
    addWeighInTournament({ commit }, payload) {
      setDoc(doc(db, 'Events', payload.eventID), {
        createdDate: payload.createdDate,
        eventType: payload.eventType,
        adminID: payload.adminID,
        eventID: payload.eventID,
        email: payload.email,
        eventDate: payload.eventDate,
        bodyOfWater: payload.bodyOfWater
      })
        .then(
          commit('LOAD_WEIGH_IN', payload),
        )
        .catch((error) => {
          console.log('ERROR ADDING WEIGH IN');
          console.log(error);
        });
    },
    loadWeighInTournament({ commit }, payload) {
      const docRef = doc(db, 'Events', payload);
      getDoc(docRef).then((doc) => {
        if (doc.data() != null) {
          commit('LOAD_TOURNAMENT', doc.data());

          // Load the teams from DB
          getDocs(collection(db, 'Events/' + payload + '/Teams')).then(
            (snapshot) => {
              const teams = [];
              snapshot.docs.forEach((doc) => {
                teams.push(doc.data());
              });
              commit('LOAD_WEIGH_IN_TEAMS', teams),
              this.dispatch('weighInTeamsListener', payload);
            }
          );
        }
      });
    },
    addWeighInTeam({commit}, payload) {
      setDoc(doc(db, 'Events/' + payload.eventID + '/Teams/' + payload.id),
        {
          ...payload,
        });
      commit('ADD_WEIGH_IN_TEAM', payload)
    },
    updateWeighInTeam({ commit }, payload) {
      // payload should be a team object
      setDoc(doc(db, 'Events', payload.eventID + '/Teams/' + payload.id), {
        ...payload,
      }).then(() => {
        console.log('lets just see what happens');
      });
    },
    deleteWeighInTeam({ commit }, payload) {
      deleteDoc(doc(db, 'Events/' + payload.eventID + '/Teams/', payload.id));
      commit('EMPTY_MUTATION');
    },
    weighInTeamsListener({ commit }, payload) {
      //listen to the catches collection for any new or removed catch documents
      const q = query(collection(db, 'Events/' + payload + '/Teams'));
      onSnapshot(q, (querySnapshot) => {
        console.log('LISTENER - CHANGE DETECTED TO WEIGH IN TEAMS');
        const teams = [];
        querySnapshot.forEach((doc) => {
          teams.push(doc.data());
        });
        commit('LOAD_WEIGH_IN_TEAMS', teams);
        commit('UPDATE_WEIGH_IN_STANDINGS');
      });
    }

  },
  mutations: {
    SET_EVENT_EXISTS_STATUS(state, payload) {
      state.eventExists = payload;
    },
    SET_EVENT_QUERY_INFO(state, payload) {
      state.eventQueryInfo = payload;
    },
    LOAD_TOURNAMENT(state, payload) {
      // console.log('LOAD_TOURNAMENT MUTATION');
      state.eventType = payload.eventType;
      state.adminID = payload.adminID;
      state.eventID = payload.eventID;
      state.eventDate = payload.eventDate;
      state.scoringSystem = payload.scoringSystem;
      state.email = payload.email;
      state.bodyOfWater = payload.bodyOfWater;
      state.standings = [];
    },
    UNLOAD_TOURNAMENT(state) {
      state.eventExists = '';
      state.eventType = '';
      state.adminID = '';
      state.eventID = '';
      state.eventDate = '';
      state.scoringSystem = '';
      state.email = '';
      state.bodyOfWater = '';
      state.teams = [];
      state.catches = [];
      state.standings = [];
      state.bigFish = '';
      state.activeTeam = '';
    },
    ADD_FISH(state, payload) {
      state.catches.push(payload);
    },
    DELETE_FISH(state, payload) {
      state.catches.pop(payload);
    },
    LOAD_CATCHES(state, payload) {
      // console.log('LOAD_CATCHES MUTATION');
      state.catches = payload;
    },
    LOAD_TEAMS(state, payload) {
      state.teams = payload;
    },
    SET_ACTIVE_TEAM(state, payload) {
      state.activeTeam = payload;
    },
    UPDATE_STANDINGS(state) {
      // console.log('UPDATE_STANDINGS MUTATION');
      state.standings = [];

      //Update each team's individual standings and update the array
      state.teams.forEach(function (team) {
        let teamData = getTeamResults(
          team.name,
          state.catches,
          state.scoringSystem
        );
        state.standings.push(teamData);
      });
      state.standings = sortStandings(state.standings);
      state.standings = calculateWeightBehindFirstPlace(state.standings);
      state.bigFish = getBigFishOfEvent(state.catches);
    },
    MOST_RECENT_FISH(state, payload) {
      state.mostRecentFish = payload;
    },
    EMPTY_MUTATION() {
      console.log('EMPTY_MUTATION');
    },
    
    // ==================================
    // WEIGH IN MUTATIONS FROM HERE DOWN
    // ==================================
    LOAD_WEIGH_IN(state, payload) {
      console.log('LOAD_WEIGH_IN MUTATION');
      state.eventType = payload.eventType;
      state.adminID = payload.adminID;
      state.eventID = payload.eventID;
      state.eventDate = payload.eventDate;
      state.email = payload.email;
      state.bodyOfWater = payload.bodyOfWater;
    },
    ADD_WEIGH_IN_TEAM(state, payload) {
      state.weighInTeams.push(payload);
    },
    LOAD_WEIGH_IN_TEAMS(state, payload) {
      state.weighInTeams = payload;
    },
    UPDATE_WEIGH_IN_STANDINGS(state) {
      state.weighInTeams = identifyWeighInBigFish(state.weighInTeams);
      state.weighInTeams = sortWeighInTeams(state.weighInTeams);
    },
  },
  getters: {
    getEventExistsStatus(state) {
      return state.eventExists;
    },
    getEventQueryInfo(state) {
      return state.eventQueryInfo;
    },
    getEventType(state) {
      return state.eventType;
    },
    getEventTeams(state) {
      return state.teams;
    },
    getBigFish(state) {
      return state.bigFish;
    },
    getAdminID(state) {
      return state.adminID;
    },
    getEventID(state) {
      return state.eventID;
    },
    getEventDate(state) {
      return state.eventDate;
    },
    getBodyOfWater(state) {
      return state.bodyOfWater;
    },
    getEventScoringSystem(state) {
      return state.scoringSystem;
    },
    getCurrentStandings(state) {
      return state.standings;
    },
    getMostRecentFish(state) {
      return state.mostRecentFish;
    },
    getAllCatches(state) {
      return state.catches;
    },
    // ==================================
    // WEIGH IN GETTERS FROM HERE DOWN
    // ==================================
    getAllWeighInTeams(state) {
      return state.weighInTeams;
    }
  },
});
