<template>
  <div>
    <top-nav></top-nav>

      <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <h2 class="pageHeaderText mt-1 mb-2">CREATE WEIGH IN</h2>

        <b-card
          id="createCard"
          class="ml-2 mr-2"
          bg-variant=""
          text-variant="white"
        >
          <form action="">
            <!-- SELECT EVENT DATE -->
            <b-form-group
              class="mt-n3 text-left"
              label="Select Date:"
              label-for="eventDate"
            >
              <b-form-datepicker
                id="eventDate"
                v-model="eventDate"
                :min="min"
              >
              </b-form-datepicker>
            </b-form-group>

            <!-- ENTER BODY OF WATER -->
            <b-form-group
              class="mt-n1 text-left"
              label="Body Of Water:"
              label-for="bodyOfWater"
            >
              <b-form-input
                id="bodyOfWater"
                v-model="bodyOfWater"
                placeholder="Enter body of water"
                required
                :state="bodyOfWaterState"
                aria-describedby="bodyOfWater-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="bodyOfWater-feedback">
                {{ this.bodyOfWaterErrorMessage }}
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- ENTER EMAIL -->
            <!-- <b-form-group
              class="mt-n1 text-left"
              label="Email:"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Enter your email"
                required
                :formatter="formatter"
                :state="emailState"
                aria-describedby="email-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="email-feedback">
                {{ this.emailErrorMessage }}
              </b-form-invalid-feedback>
            </b-form-group> -->

            <b-row align-h="center">
              <b-button
                class="mt-3 w-100"
                variant="success"
                size="lg"
                :disabled="createDisableState"
                @click="addWeighIn()"
              >
                CREATE WEIGH IN
              </b-button>
            </b-row>
            <b-row
              v-if="this.currentENV == 'development'"
              align-h="center"
            >
            </b-row>
          </form>
        </b-card>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import TopNav from '../components/TopNav.vue';
import { generateEventId, generateBasicId } from '../js/utilities.js';
export default {
  components: {
    TopNav,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      eventDate: null,
      min: minDate,
      bodyOfWater: '',
      bodyOfWaterErrorMessage: '',
      emailErrorMessage: '',
      email: '',
      bodyOfWaterMinChars: 2,
      bodyOfWaterMaxChars: 24
    };
  },
  created() {
    this.$store.commit('UNLOAD_TOURNAMENT');
  },
  computed: {
    currentENV() {
      return process.env.NODE_ENV;
    },
    bodyOfWaterState() {
      return this.validateBodyOfWater();
    },
    emailState() {
      return this.validateEmailAddress();
    },
    createDisableState() {
      return !this.formIsValid();
    },
    getEventID() {
      return this.$store.getters['eventEventID'];
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase();
    },
    validateBodyOfWater() {

      // CHECK IF A BODY OF WATER WAS ENTERED
      if (this.bodyOfWater == '') {
        this.bodyOfWaterErrorMessage = "Please enter body of water where tournament will take place."
        return false;
      }

      // MAKE SURE BODY OF WATER DOES NOT HAVE INVALID CHARACTERS
      if (!this.bodyOfWater.match(/^[A-Za-z0-9&\-/+. ]+$/)) {
        this.bodyOfWaterErrorMessage = 'Body of water has invalid characters.';
        return false;
      }

      // MAKE SURE BODY OF WATER IS THE RIGHT LENGTH 
      if (this.bodyOfWater.length < this.bodyOfWaterMinChars || this.bodyOfWater.length > this.bodyOfWaterMaxChars) {
        this.bodyOfWaterErrorMessage = `Body of water should be between ${this.bodyOfWaterMinChars} and ${this.bodyOfWaterMaxChars} characters.`;
        return false;
      }
      return true;
    },
    validateEmailAddress() {
      // CHECK IF A EMAIL WAS ENTERED
      if (this.bodyOfWater == '') {
        this.emailErrorMessage = "Please enter an email address."
        return false;
      }
      // CHECK IF EMAIL FORMAT IS CORRECT
      var checker = /\S+@\S+\.\S+/;
      if (!checker.test(this.email)) {
        this.emailErrorMessage = "Incorrect email format."
        return false;
      }
      return true;
    },
    formIsValid() {
      
      // DATE HAS BEEN SELECTED
      if (!this.eventDate) return false;

      // BODY OF WATER IS OK
      if (!this.validateBodyOfWater()) return false;

      // EMAIL IS OK
      // if (!this.validateEmailAddress()) return false;

      // OTHERWISE WE'RE GOOD
      return true;
    },
    addWeighIn() {
      const newAdminID = generateEventId(7);
      const newEventID = generateEventId(7);

      let todaysDate = new Date();
      todaysDate = moment(todaysDate).format('YYYY-MM-DD');

      //add the tournament
      this.$store.dispatch('addWeighInTournament', {
        createdDate: todaysDate,
        eventType: 'WEIGH-IN',
        adminID: newAdminID,
        eventID: newEventID,
        eventDate: this.eventDate,
        email: this.email,
        bodyOfWater: this.bodyOfWater
      });
      this.$router.push({ path: 'confirm-weigh-in' });
    }
  },
};
</script>
<style>
#createCard {
  border: 1px solid var(--color30);
  background-color: var(--supplemental-color);
}
.text-warning {
  color: var(--color10);
}
#createEventButtons.btn {
  background-color: var(--color10);
  border-color: var(--color30);
  border: 1px solid transparent;
}
#createEventButtons:hover {
  background-color: var(--color10);
  border-color: var(--color30);
  border: 1px solid transparent;
}
</style>
