<template>
  <div>
    <ul class="vue-tabz" :style="cssVars">
      <li
        v-for="(tabData, i) in data"
        :key="tabData"
        :class="{ active: currentIndex === i }"
        @click="changeIndex(i)"
      >
        {{ tabData }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  data() {
    return {
      currentIndex: 0,
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    mainColor: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "",
    },
  },
  computed: {
    cssVars() {
      return {
        "--main-color": `#${this.mainColor.replace('#', '')}`,
        "--max-width": `${parseInt(this.maxWidth, 10)}px`,
      };
    },
  },
  methods: {
    /**
     * Change current index based on tab index
     *
     * @param {Number} index tab index
     * @return void
     */
    changeIndex(index) {
      this.currentIndex = index;

      this.$emit("clickedTab", {
        index,
        tab: this.data[index],
      });
    },
  },
};
</script>

<style>
  .vue-tabz {
    font-family: var(--leaderboard-font);
    transform: scaleY(1.2);
    color: var(--color30);
    display: flex;
    font-size: 1em;
    height: 30px;
    justify-content: space-between;
    line-height: 16px;
    list-style-type: none;
    margin: 0;
    max-width: var(--max-width);
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .vue-tabz li {
    font-weight: normal;
    border-bottom: solid 1px var(--color30);
    cursor: pointer;
    opacity: 0.7;
    padding-bottom: 12px;
    text-transform: uppercase;
    width: 100%;
  }

  .vue-tabz li.active {
    font-weight: bold;
    border-bottom: solid 3px var(--color30);
    opacity: 1;
  }

  @media only screen and (max-width: 640px) {
    .vue-tabz {
        padding: 0;
    }
  }
</style>