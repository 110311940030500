<template>
  <div>
    <b-row class="mt-3 mb-1">
      <b-col>
        <b-icon
          id="create"
          class="mb-2 home-icon"
          icon="trophy"
          style="width: 50px; height: 50px"
          @click="goToPage('create-tournament')"
        >
        </b-icon>
        <h6 class="home-iconText">TOURNAMENT</h6>
      </b-col>
      <b-col>
        <b-icon
          id="view"
          class="mb-2 home-icon"
          icon="list-ol"
          style="width: 50px; height: 50px"
          @click="goToPage('create-weigh-in')"
        ></b-icon>
        <h6 class="home-iconText">LIVE WEIGH IN</h6>
      </b-col>
      <b-col>
        <b-icon
          id="join"
          class="mb-2 home-icon"
          icon="door-open"
          style="width: 50px; height: 50px"
          @click="goToPage('join')"
        ></b-icon>
        <h6 class="home-iconText">JOIN/VIEW</h6>
      </b-col>
      

    </b-row>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {};
  },
  methods: {
    goToPage(page) {
      this.$router.push({ path: page });
    },
  }
};
</script>
