function getTeamResults(name, catches, scoringSystem) {
  // Filter on the Team
  const filteredCatches = catches.filter((fish) => fish.name === name);

  //Get Team's Big Fish
  let bigFish = 0;
  if (filteredCatches.length != 0) {
    bigFish = findTeamBigFish(filteredCatches);
  }

  // Get the total weight of all fish
  let totalWeight = 0;
  if (filteredCatches.length != 0) {
    filteredCatches.forEach(function (fish) {
      totalWeight = +totalWeight + +fish.weight;
    });
  }

  // Figure out average weight of all fish
  let average = 0;
  if (filteredCatches.length != 0) {
    average = totalWeight / filteredCatches.length;
  }

  // Figure Out Best Five if Needed
  if (scoringSystem == 'Best Five Fish') {
    let bestFive = 0;
    if (filteredCatches.length <= 5) {
      bestFive = totalWeight;
    } else {
      filteredCatches.sort(function (a, b) {
        return b.weight - a.weight;
      });

      let fishCounter = 0;
      filteredCatches.forEach(function (fish) {
        if (fishCounter < 5) {
          bestFive = +bestFive + +fish.weight;
          fishCounter++;
        }
      });
    }
    totalWeight = bestFive;
  }

  // Get time of last fish caught
  let lastCatch = 'N/A';
  let elapsedTimeSinceLastCatch = 'N/A';

  if (filteredCatches.length != 0) {
    filteredCatches.sort(function (a, b) {
      return b.timeStamp - a.timeStamp;
    });

    lastCatch = filteredCatches[0].time;

    // Get elapsed time since last fish was caught
    elapsedTimeSinceLastCatch = Date.now() - filteredCatches[0].timeStamp;

    elapsedTimeSinceLastCatch =
      Math.floor(elapsedTimeSinceLastCatch / 1000 / 60) + ' min ago';
  }

  return {
    name: name,
    catches: filteredCatches.length,
    big: bigFish.toFixed(2),
    total: totalWeight.toFixed(2),
    average: average.toFixed(2),
    lastCatch: lastCatch,
    timeSinceLastCatch: elapsedTimeSinceLastCatch,
    place: '',
  };
}

function sortStandings(standings) {
  standings.sort(function (a, b) {
    return b.total - a.total;
  });

  // Assign a position to each team based on their total weight.
  // If the team has not caught a fish display a dash as the place.

  // Also if there is a tie score, we give those scores the same place.
  let previousTotal = '99.99';
  for (let i = 0; i < standings.length; i++) {
    if (standings[i].total == '0.00') {
      standings[i].place = '-';
    } else {
      if (previousTotal == standings[i].total) {
        // found a tie score
        standings[i].place = standings[i - 1].place;
      } else {
        standings[i].place = i + 1;
      }
      previousTotal = standings[i].total;
    }
  }
  return standings;
}

function calculateWeightBehindFirstPlace(standings) {
  for (let i = 0; i < standings.length; i++) {
    // handle teams that do not have any weight
    if (standings[i].total == '0.00') {
      standings[i].weightBehind = '-';
    }

    // handle first place
    if (standings[i].place == '1') {
      standings[i].weightBehind = '-';
    }

    // handle everyone else
    if (standings[i].place != '1') {
      let weightBehind = standings[0].total - standings[i].total;
      standings[i].weightBehind = String(weightBehind.toFixed(2));
    }
  }
  return standings;
}

function findTeamBigFish(catches) {
  if (catches.length == 0) {
    return 0.0;
  } else {
    return Math.max.apply(
      Math,
      catches.map(function (fish) {
        let big = new Number(fish.weight);
        return big.toFixed(2);
      })
    );
  }
}

function getBigFishOfEvent(catches) {
  let bigFish = Math.max.apply(
    Math,
    catches.map(function (fish) {
      return fish.weight;
    })
  );

  let name = '';
  let time = '';
  let timeStamp = '';
  catches.forEach(function (fish) {
    if (fish.weight == bigFish) {
      name = fish.name;
      time = fish.time;
      timeStamp = fish.timeStamp;
    }
  });
  return {
    name: name,
    weight: bigFish.toFixed(2),
    time: time,
    timeStamp: timeStamp,
  };
}

// ===================================
// WEIGH IN SPECIFIC FUNCTIONS
// ===================================
function sortWeighInTeams(teams) {
  teams.sort(function (a, b) {
    return b.totalWeight - a.totalWeight;
  });

  // Assign a position to each team based on their total weight.
  // If the team has not caught a fish display a dash as the place.

  // Also if there is a tie score, we give those scores the same place.
  let previousTotal = '99.99';
  for (let i = 0; i < teams.length; i++) {
    if (teams[i].totalWeight == '0.00') {
      teams[i].place = '-';
    } else {
      if (previousTotal == teams[i].totalWeight) {
        // found a tie score
        teams[i].place = teams[i - 1].place;
      } else {
        teams[i].place = i + 1;
      }
      previousTotal = teams[i].totalWeight;
    }
  }
  return teams;
}

function identifyWeighInBigFish(teams) {
  let bigFish = Math.max.apply(
    Math,
    teams.map(function (team) {
      return team.bigFishWeight;
    })
  );

  teams.forEach((team) => {
    if (team.bigFishWeight == bigFish) {
      team.isBigFish = true;
    }
    else {
      team.isBigFish = false;
    }
  })
  return teams;
}

export {
  getTeamResults,
  sortStandings,
  getBigFishOfEvent,
  calculateWeightBehindFirstPlace,
  sortWeighInTeams,
  identifyWeighInBigFish
};
