<template>
  <div>
    <top-nav></top-nav>
    <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-4">
      <b-container>
        <h2 class="pageHeaderText mt-1 mb-2"><strong>WEIGH IN CREATED!</strong></h2>
        <p class="confirm-secondaryText text-left">Below are your weigh in details...</p>
      </b-container>
      <b-card
        id="confirm-card"
        class="ml-2 mr-2 mt-2 mb-5"
        bg-variant=""
        text-variant="white"
      >
        <b-row align-h="center">
          <p class="confirm-primaryText mr-2">DATE:</p>
          <p class="confirm-event-value"> {{ this.eventDate }}</p>
        </b-row>
      
        <b-row align-h="center">
          <p class="confirm-primaryText mr-2">LOCATION:</p>
          <p class="confirm-event-value">{{ this.bodyOfWater }}</p>
        </b-row>

        <b-row class="dflex justify-content-center">
          <h1 id="eventId" class="mt-3 confirmEventID">Event: <span class="confirm-id-colors">{{ this.eventID }}</span>
            <b-button size="sm ml-2" variant="outline-light" @click="copyCodeToBuffer('copyEventID', 'event')">
              <b-icon icon="clipboard"></b-icon>
            </b-button>
          </h1>
        </b-row>
        <b-row class="dflex justify-content-center">
          <p class="confirm-copyEventText" id="copyEventID"></p>
        </b-row>

        <b-row class="dflex justify-content-center">
          <h1 id="adminId" class="mt-3 confirmEventID">Admin: <span class="confirm-id-colors">{{ this.adminID }}</span>
            <b-button size="sm ml-2" variant="outline-light" @click="copyCodeToBuffer('copyAdminID', 'admin')">
              <b-icon icon="clipboard"></b-icon>
            </b-button>
          </h1>
        </b-row>
        <b-row class="dflex justify-content-center">
          <p class="confirm-copyEventText" id="copyAdminID"></p>
        </b-row>

      <hr class="mt-3 mb-3 globalSeparator" />
      <b-row>
        <p class="confirm-secondaryText">Please share the <strong>Event Code</strong> with your participants. They will use it to join the weigh in on the day of the event.</p>
      </b-row>

      <b-row class="mt-3">
        <p class="confirm-secondaryText"><strong>Tournament Director:</strong>Save the <strong>Admin Code</strong> code for yourself. You will use this to administer the weigh in.</p>
      </b-row>
        <b-button
          class="mt-2"
          variant="success"
          size="lg"
          @click="joinEvent()"
        >
          JOIN NOW
        </b-button>
      </b-card>
      </div>
  </div>
</template>

<script>
import { beautifyDate } from '../js/utilities.js';
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav,
  },
  data() {
    return {
      eventIsToday: false,
      showDismissibleAlert: false
    };
  },
  created() {
    if (!this.eventID) {
      this.$router.push({ path: '/' });
    } 
  },
  computed: {
    eventTeams() {
      return this.$store.getters['getEventTeams'];
    },
    eventType() {
      return this.$store.getters['getEventType'];
    },
    adminID() {
      return this.$store.getters['getAdminID'];
    },
    eventID() {
      return this.$store.getters['getEventID'];
    },
    eventDate() {
      return beautifyDate(this.$store.getters['getEventDate']);
    },
    eventScoringSystem() {
      return this.$store.getters['getEventScoringSystem'];
    },
    bodyOfWater() {
      return this.$store.getters['getBodyOfWater'];
    }
  },
  methods: {
    joinEvent() {
      localStorage.id = this.eventID;
      localStorage.activeUser = 'ADMIN'
      this.$router.push({ path: 'live-weighin' });
    },
    copyCodeToBuffer(id, codeType) {
      if (codeType == 'event') {
        navigator.clipboard.writeText(this.eventID);
        document.getElementById(id).innerHTML = "Event code copied";
      } else if (codeType == 'admin') {
        navigator.clipboard.writeText(this.adminID);
        document.getElementById(id).innerHTML = "Admin code copied";
      }
      setTimeout(function () {
        document.getElementById(id).innerHTML = "";
      }, 1000);
    }
  },
};
</script>
<style>
#confirm-card {
    border: 1px solid var(--color30);
    background-color: var(--supplemental-color);
  }
.confirm-primaryText {
  color: var(--primary-font-color);
  font-size: 1.4em;
  margin: 0;
}
.confirm-secondaryText {
  color: var(--primary-font-color);
  font-size: 1.25em;
  margin: 0;
}
.confirm-event-value {
  color: var(--color30);
  font-size: 1.4em;
  margin: 0;
}
.confirmEventID {
  color: var(--primary-font-color);
  font-size: 2em;
  margin: 0;
}
.confirm-id-colors {
  color: var(--color30);
}
.confirm-copyEventText {
  color: var(--primary-font-color);
  font-size: .8em;
  margin: 0;
}
.confirm-event-is-today {
  color: var(--color30);
  font-size: 1.5em;
  font-weight: bold;
}
</style>
