<template>
  <div>
    <top-nav></top-nav>
    <div class="termsOfService pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <b-container>
        <div id="topOfTerms" class="">
          <h3 class="termsSectionHeader">Terms of Service Overview</h3>
          <p>
            Below is an overview of our Terms of Service for our “Platform”, which means any website, application,
            or service we offer. You should read the complete Terms of Service because that document (and not this
            overview)
            is our legally binding agreement. The Terms of Service includes information about your legal rights and
            covers
            areas such as limitations of liability, resolution of disputes by mandatory arbitration rather than a judge
            or jury in a court of law, and a class action waiver.
          </p>

          <h3 class="termsSectionHeader">Your Relationship with CastClash</h3>
          <ul>
            <li class="text-left">
              By using our Platform, you are agreeing to our Terms of Service. The Terms of Service are a legally
              binding agreement between you and CastClash.
            </li>
          </ul>

          <h3 class="termsSectionHeader">CastClash Tournaments and Weigh Ins</h3>
          <ul>
            <li class="text-left">
              Organizers of these events may establish criteria for their own events.
            </li>
            <li class="text-left">
              Organizers may charge fees for their own events but CastClash is not responsibile for any disputes raised
              from using CastClash when money is involved.
            </li>
            <li class="text-left">
              CastClash is not responsibile for any rules viloations set in place for the event organizer for an event
              hosted on CastClash.
            </li>


          </ul>

          <h3 class="termsSectionHeader">Users of CastClash</h3>
          <ul>
            <li class="text-left">
              Using CastClash involves interacting real people and competitions in the real world, which can sometimes
              lead to unexpected situations.
              We can’t control what happens in the real world, and we are not responsible for it. You should use common
              sense and good judgment when
              interacting with others as an event organizer or participant in a CastClash event.
            </li>
          </ul>

          <h3 class="termsSectionHeader">Our Platform</h3>
          <ul>
            <li class="text-left">
              We try hard to make sure that our Platform is always available and working, but we cannot guarantee it
              will be. Occasionally things
              may not go exactly as planned. We apologize in advance for any inconvenience.
            </li>
            <li class="text-left">
              We are continually improving our Platform. This means that we may modify or discontinue portions of our
              Platform.
            </li>
            <li class="text-left">
              By using our Platform, you agree to the limitations of liability and release in our Terms of Service. You
              also agree
              to resolve any disputes you may have with us in the manner described in the Terms of Service.
            </li>
          </ul>

          <h3 class="termsSectionHeader">TERMS OF SERVICE</h3>
          <h2 class="text-left">1. Introduction</h2>
          <p class="text-left">Welcome to CastClash. By accessing our website, located at CastClash.com, you agree to be bound by these
            Terms of Service ("Terms"), all applicable laws and regulations, and agree that you are responsible for
            compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited
            from using or accessing this site.</p>

          <h2 class="text-left">2. Use License</h2>
          <p class="text-left">
            a. Permission is granted to temporarily download one copy of the materials (information or software) on
            CastClash's website for personal, non-commercial transitory viewing only. This is the grant of a
            license, not a transfer of title, and under this license, you may not:
          <ul>
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
            </li>
            <li>attempt to decompile or reverse engineer any software contained on CastClash's website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
          </ul>
          </p>
          <p class="text-left">b. This license shall automatically terminate if you violate any of these restrictions and may be
            terminated
            by CastClash at any time. Upon terminating your viewing of these materials or upon the termination
            of this license, you must destroy any downloaded materials in your possession whether in electronic or
            printed format.</p>

          <h2 class="text-left">3. Disclaimer</h2>
          <p class="text-left">The materials on CastClash's website are provided on an 'as is' basis. CastClash makes
            no warranties, expressed or implied, and hereby disclaims and negates all other warranties including,
            without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose,
            or non-infringement of intellectual property or other violation of rights.</p>

          <h2 class="text-left">4. Limitations</h2>
          <p class="text-left">In no event shall CastClash or its suppliers be liable for any damages (including, without
            limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or
            inability to use the materials on CastClash's website, even if CastClash or a CastClash authorized representative 
            has been notified orally or in writing of the possibility of such damage.</p>

          <h2 class="text-left">5. Accuracy of Materials</h2>
          <p class="text-left">The materials appearing on CastClash's website could include technical, typographical, or
            photographic errors. CastClash does not warrant that any of the materials on its website are
            accurate, complete, or current. CastClash may make changes to the materials contained on its
            website at any time without notice. However, CastClash does not make any commitment to update the
            materials.</p>

          <h2 class="text-left">6. Links</h2>
          <p class="text-left">CastClash has not reviewed all of the sites linked to its website and is not responsible for the
            contents of any such linked site. The inclusion of any link does not imply endorsement by CastClash of the site. 
            Use of any such linked website is at the user's own risk.</p>

          <h2 class="text-left">7. Modifications</h2>
          <p class="text-left">CastClash may revise these Terms of Service for its website at any time without notice. By using
            this website you are agreeing to be bound by the then current version of these Terms of Service.</p>

          <h2 class="text-left">8. Governing Law</h2>
          <p class="text-left">These terms and conditions are governed by and construed in accordance with the laws of the United States of America
            and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

          <h3>April 18, 2024</h3>
        </div>
      </b-container>
    </div>

  </div>
</template>
<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav
  },
  computed:  {
    isMobile() {
      // console.log(screen.width);
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }

  }
};
</script>
<style>
.termsOfService {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.termsSectionHeader {
  font-weight: bold;
}
</style>