import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import CreateTournament from '../views/CreateTournament.vue';
import CreateWeighIn from '../views/CreateWeighIn.vue';
import ConfirmTournament from '../views/ConfirmTournament.vue';
import ConfirmWeighIn from '../views/ConfirmWeighIn.vue';
import Join from '../views/Join.vue';
import About from '../views/About.vue';
import TermsOfService from '../views/TermsOfService.vue';
import Welcome from '../views/Welcome.vue';
import LiveScorer from '../views/LiveScorer.vue';
import LiveWeighIn from '../views/LiveWeighIn.vue';
import HowToTournament from '../views/HowToTournament.vue';
import HowToWeighIn from '../views/HowToWeighIn.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'CastClash' }
  },
  {
    path: '/create-tournament',
    name: 'CreateTournament',
    component: CreateTournament,
    meta: { title: 'CastClash Create' }
  },
  {
    path: '/create-weigh-in',
    name: 'CreateWeighIn',
    component: CreateWeighIn,
    meta: { title: 'CastClash Create' }
  },
  {
    path: '/confirm-tournament',
    name: 'ConfirmTournament',
    component: ConfirmTournament,
    meta: { title: 'CastClash Confirmation' }
  },
  {
    path: '/confirm-weigh-in',
    name: 'ConfirmWeighIn',
    component: ConfirmWeighIn,
    meta: { title: 'CastClash Confirmation' }
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
    meta: { title: 'CastClash Join' }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: { title: 'CastClash Welcome' }
  },
  {
    path: '/live-scorer',
    name: 'LiveScorer',
    component: LiveScorer,
    meta: { title: 'CastClash Live Scorer' }
  },
  {
    path: '/live-weighin',
    name: 'LiveWeighIn',
    component: LiveWeighIn,
    meta: { title: 'CastClash Weigh In' }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'CastClash - About' }
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: { title: 'CastClash - Terms Of Service' }
  },
  {
    path: '/how-to-tournament',
    name: 'HowToTournament',
    component: HowToTournament
  },
  {
    path: '/how-to-weigh-in',
    name: 'HowToWeighIn',
    component: HowToWeighIn
  },
  ...process.env.NODE_ENV !== 'production' ? [{ path: '/test', name: 'Test', component: () => import('../views/Test.vue') },] : [],
  ...process.env.NODE_ENV !== 'production' ? [{ path: '/admin', name: 'Admin', component: () => import('../views/Admin.vue') },] : [],
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title || 'CastClash';
});

export default router;
