<template>
  <b-modal
    id="add-team-to-weigh-in-modal"
    title="Add Team"
    hide-footer
    @show="resetAddTeamModal"
  >
    <!-- <p>TEAM: {{ team }}</p> -->

    <b-form-group label="Team Name:" label-for="teamName">
      <b-form-input
        id="teamName"
        v-model="teamName"
        placeholder="Enter team name"
        required
        :formatter="formatter"
        :state="teamNameState"
        aria-describedby="teamName-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="teamName-feedback">
        {{ this.teamNameErrorMessage }}
      </b-form-invalid-feedback>
    </b-form-group>
    
    <b-form-group label="Team Number (optional):" label-for="teamNumber">
      <b-form-input
        id="teamNumber"
        v-model="teamNumber"
        type="number"
      ></b-form-input>
    </b-form-group>
    
    <b-button class="mt-3" size="lg"variant="success" block @click="handleOk">ADD TEAM</b-button>
    
  </b-modal>

</template>
<script>
import { generateBasicId } from '../js/utilities.js';

export default {
  data() {
    return {
      teamName: '',
      teamNumber: '',
      teamNameErrorMessage: '',
      minChars: 2,
      maxChars: 24,
    };
  },
  computed: {
    weighInTeams() {
      return this.$store.getters['getAllWeighInTeams'];
    },
    teamNameState() {
      return this.validateTeamName();
    }
  },
  methods: {
    formatter(value) {
      return value.toUpperCase();
    },
    resetAddTeamModal() {
      this.teamName = '';
      this.teamNumber = '';
    },
    validateTeamName() {
      console.log('CHECKING NAME: ' + this.teamName);

      // CHECK IF A NAME WAS ENTERED
      if (this.teamName == '') {
        this.teamNameErrorMessage = 'Please enter a team name.';
        return false;
      }

      // MAKE SURE NAME DOES NOT HAVE INVALID CHARACTERS
      if (!this.teamName.match(/^[A-Za-z0-9&\-/+ ]+$/)) {
        this.teamNameErrorMessage = 'Team name has invalid characters.';
        return false;
      }

      // MAKE SURE NAME IS THE RIGHT LENGTH 
      if (this.teamName.length < this.minChars || this.teamName.length > this.maxChars) {
        this.teamNameErrorMessage = `Team names should be between ${this.minChars} and ${this.maxChars} characters.`;
        return false;
      }

      // MAKE SURE THE NAME IS NOT A DUPLCIATE
      if (this.weighInTeams.filter(e => e.name === this.teamName).length > 0) {
        this.teamNameErrorMessage = 'Duplicate team name.';
        return false;
      }
      return true;
    },
    handleOk(bvModalEvent) {
      if (this.teamNameState) {
        console.log('Team name is OK');
        this.$bvModal.hide('add-team-to-weigh-in-modal');
        this.addTeam();
      } else {
        console.log('Team name is NO BUENO');
        bvModalEvent.preventDefault();
      }
    },
    addTeam() {
      let newTeam = {
        eventID: localStorage.id,
        id: generateBasicId(16),
        name: this.teamName,
        teamNumber: Number(this.teamNumber),
        totalWeight: 0.00,
        bigFishWeight: 0.00,
        numberOfFish: 0,
        place: ''
      }
      this.$store.dispatch('addWeighInTeam', newTeam);
    },
  },
};
</script>