<template>
  <div class="nav-bg">
    <b-navbar toggleable="md" type="dark" variant="transparent">
      <router-link class="navbarBrand nav-title" to="/" tag="span" style="cursor: pointer">
        <b-img id="nav-icon" class="m0 p0" :src="require('../images/nav-icon.png')"></b-img>
        CASTCLASH
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto ">
          
          <b-navbar-nav>
            <b-nav-item id="nav-tournaments" to="/create-tournament">TOURNAMENTS</b-nav-item>
            <b-nav-item id="nav-weigh-ins" to="/create-weigh-in">WEIGH INS</b-nav-item>
            <b-nav-item id="nav-join" to="/join">JOIN EVENT</b-nav-item>
            <!-- <b-nav-item id="nav-tutorials" to="/tutorials">TUTORIALS</b-nav-item> -->
            <b-nav-item-dropdown text="HOW TO" right>
              <b-dropdown-item to="/how-to-tournament">CREATE TOURNAMENTS</b-dropdown-item>
              <b-dropdown-item to="/how-to-weigh-in">HOST WEIGH INS</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Test',
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    navigateHomeThenTo(id) {
      this.$router.push({ path: '/' })
      .then ( () => {
        document.getElementById(id).scrollIntoView();
      } 
      )
    }
  }
}
</script>
<style>
.navbarBrand {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 28px;
  font-weight:bold;
  font-style: normal;
  color: white;
  margin: 0;
}
#nav-icon {
  height: 75px;
  width: 75px;
}
</style>
