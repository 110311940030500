<template>
  <div>
    <top-nav></top-nav>
    <h2 class="pageHeaderText mt-1 mb-2">HOSTING CASTCLASH WEIGH INS</h2>

    <!-- HOW CAN IT GET BETTER? -->
    <div class="lightSection">
      <b-container>
        <h2 class="home-sectionTitle">Tournament Angler Instructions</h2>

        <h4 class="home-sectionText">
            <strong>Step 1.</strong> Get the <strong>EVENT CODE</strong> from the Tournament Director and open <strong>CastClash.com</strong> on your mobile device.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 2.</strong> Go to the <strong>JOIN EVENT</strong> screen, select the <strong>WEIGH IN</strong> tab and enter your <strong>Event Code</strong> then press the <strong>JOIN</strong> button.
        </h4>

        <h4 class="home-sectionText mb-5">
            <strong>Step 3.</strong> You are now in the LIVE weigh in. Simply watch as the Tournament Director updates the standings.
        </h4>


        <h2 class="home-sectionTitle">Tournament Director Instructions</h2>

        <h4 class="home-sectionText">
          As a <strong>Tournament Director</strong>, you can use CastClash to virtually host an actual tournament Weigh In. This allows your tournament participants to watch the standings update on their mobile device as each team weighs in.
        </h4>

        <h3 class="home-sectionText">
          <strong>Prior to the Weigh In...</strong>
        </h3>

        <h4 class="home-sectionText">
            <strong>Step 1.</strong> As the Tournament Director, go to the <strong>WEIGH INS</strong> screen.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 2.</strong> Enter the date that you'd like to host your tournament weigh in.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 3.</strong> Enter the name of the Lake or River where your tournament will take place.
        </h4>
        
        <h4 class="home-sectionText">
          <strong>Step 4.</strong> Once the fields have been filled, click <strong>CREATE WEIGH IN</strong>.
        </h4>
        <b-img class="W0" :src="require('../images/tutorials/W0.jpg')" fluid alt="Responsive image"></b-img>

        <h4 class="home-sectionText">
          <strong>Step 5.</strong> You will now receive a confirmation that your tournament weigh in was created. You will also recieve an <strong>Event Code</strong> to share with all of the anglers in your event and an <strong>Admin Code</strong> for you to keep. <strong>DO NOT share this code</strong>.
        </h4>
        <b-img class="W1" :src="require('../images/tutorials/W1.jpg')" fluid alt="Responsive image"></b-img>


        <!-- Add teams at registration -->
        <h3 class="home-sectionText">
          <strong>At your tournament registration...</strong>
        </h3>
        <h4 class="home-sectionText">
            <strong>Step 6.</strong> Before registering teams before the tournament, go to the <strong>JOIN EVENT</strong> screen, select the <strong>WEIGH IN</strong> tab and enter your <strong>Event Code & Admin Code</strong> then press the <strong>JOIN</strong> button.
        </h4>
        <b-img class="W2" :src="require('../images/tutorials/W2.jpg')" fluid alt="Responsive image"></b-img>

        <h4 class="home-sectionText">
          <strong>Step 7.</strong> You will now be on your standings screen for your tournament. As teams register for your tournament, add them to your tournament by clicking the <strong>ADD TEAM</strong> button.
        </h4>
        <b-img class="W3" :src="require('../images/tutorials/W3.jpg')" fluid alt="Responsive image"></b-img>

        <h4 class="home-sectionText">
            <strong>Step 8.</strong> Enter a <strong>TEAM NAME</strong> and <strong>TEAM NUMBER</strong> (Team Number is optional. Use only if you want to track blast off order). Repeat this step for each team as they register.
        </h4>
        <b-img class="W4" :src="require('../images/tutorials/W4.jpg')" fluid alt="Responsive image"></b-img>

        <h3 class="home-sectionText">
          <strong>At the Weigh In...</strong>
        </h3>

        <h4 class="home-sectionText">
            <strong>Step 9.</strong> Share only the <strong>Event Code</strong> with the anglers in your tournament. It might be helpful to have a small sign with the event code and <strong>"CastClash.com"</strong> so the anglers can easily join to watch.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 10.</strong> Join the event again using the <strong>Event Code & Admin Code</strong>. You will find all of your tournament teams listed.
        </h4>
        <b-img class="W5" :src="require('../images/tutorials/W5.jpg')" fluid alt="Responsive image"></b-img>
  
        <h4 class="home-sectionText">
            <strong>Step 11.</strong> As each team weighs in, click on the team name in the standings. Now enter the <strong>Number Of Fish</strong>, <strong>Total Weight</strong> and <strong>Big Fish Weight</strong> and click <strong>UPDATE TEAM</strong>.
        </h4>
        <b-img class="W6" :src="require('../images/tutorials/W6.jpg')" fluid alt="Responsive image"></b-img>

        <h4 class="home-sectionText">
            <strong>Step 12.</strong> Your standings are now automatically updating. You can also add more teams and even delete a team if needed. Anyone accessing your weigh in from their mobile device will also see the standings update.
        </h4>
        <b-img class="W8" :src="require('../images/tutorials/W8.jpg')" fluid alt="Responsive image"></b-img>

      </b-container>
    </div>


  </div>

</template>
<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style>
.T1 {
  height: 50%;
  width: 50%;
}
.T2 {
  height: 50%;
  width: 50%;
}
.T3 {
  height: 50%;
  width: 50%;
}
.T4 {
  height: 50%;
  width: 50%;
}
</style>