<template>
    <div>
      <top-nav></top-nav>
        <add-team-to-weigh-in-modal></add-team-to-weigh-in-modal>
        <update-weigh-in-team-modal :teamData="this.selectedTeam"></update-weigh-in-team-modal>
        
        <!-- <b-container fluid> -->
        <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-6">

        <b-container>
          <b-row>
            <h1 class="mx-auto text-white">{{bodyOfWater}}</h1>
          </b-row>

          <b-row align-h="center">
            <p class="liveScorer-eventInfoText mr-2">DATE:</p>
            <p class="liveScorer-highlighted-eventInfoText">{{ eventDate }}</p>
          </b-row>

          <b-row align-h="center">
            <p class="liveScorer-eventInfoText mr-2">EVENT:</p>
            <p class="liveScorer-highlighted-eventInfoText">#{{ eventID }}</p>
          </b-row>

          <b-row v-if="this.activeUser== 'ADMIN'">
            <b-button
              id="addWeighInTeam"
              class="mx-auto w-75"
              size="lg"
              variant="success"
              v-b-modal.add-team-to-weigh-in-modal
            >
              ADD TEAM
            </b-button>
          </b-row>
        </b-container>

        <div v-if="!weighInTeamExists">
          <h5 class="mt-5 weighIn-please-enter-teams">No teams have been added.</h5>
        </div>
        <div v-else>
          <h5 class="mt-3 text-white">STANDINGS</h5>
        </div>

        <div
          v-for="team in allWeighInTeams"
          :key="team.name"
        >
        <b-card
          id="standing"
          class="ml-2 mr-2 mb-2 mt-3"
          no-body
          @click="weighInTeamClicked(team)"
          >
          <b-row class="dflex align-items-center">
            
            <b-col class="p-0" cols="2">
              <p class="weighIn-teamPlace text-center">{{ team.place }}</p>
            </b-col>
            
            <b-col class="p-0" cols="10">
              
              <b-row class="m-0 dflex align-items-end">
                <div v-if="team.teamNumber != 0">
                  <p class="text-left weighIn-teamName">#{{  team.teamNumber }} - {{ team.name }}</p>
                </div>
                <div v-else>
                  <p class="text-left weighIn-teamName">{{ team.name }}</p>
                </div>
              </b-row>

              <b-row class="m-0 dflex align-items-center">
                <b-col class="p-0" cols="4">
                  <p class="text-left weighIn-fishCount">{{ team.numberOfFish }} FISH</p>
                </b-col>
                <b-col class="p-0" cols="4">
                  <div v-if="team.isBigFish && team.bigFishWeight != 0">
                      <p class="text-left weighIn-bigFishOwner">BIG: {{ team.bigFishWeight }}&nbsp;<b-icon icon="trophy-fill"></b-icon></p>
                      
                  </div>
                  <div v-else>
                    <p class="text-left weighIn-bigFish">BIG: {{ team.bigFishWeight }}</p>
                  </div>
                </b-col>
                <b-col class="pl-0" cols="4">
                  <p class="weighIn-totalWeight text-right"> {{ team.totalWeight }} </p>
                </b-col>
              </b-row>
          
            </b-col>
          
          </b-row>
            
          </b-card>
        </div>
      </div>
    </div>
</template>
<script>
import TopNav from '../components/TopNav.vue';
import AddTeamToWeighInModal from '../components/AddTeamToWeighInModal.vue';
import UpdateWeighInTeamModal from '../components/UpdateWeighInTeamModal.vue';
import VuejsDialog from 'vuejs-dialog';
import { beautifyDateForScoreboard } from '../js/utilities.js';
export default {
  components: {
    TopNav,
    AddTeamToWeighInModal,
    UpdateWeighInTeamModal
  },
  data() {
    return {
      teamName: '',
      teamNumber: '',
      teams: [],
      selectedTeam: ''
    };
  },
  mounted() {
    this.$store.dispatch('loadWeighInTournament', localStorage.id);
  },
  beforeRouteLeave(to, from, next) {
    let options = {
      okText: 'LEAVE',
      cancelText: 'STAY',
      customClass: '',
    };

    // Prevent the user from leaving the page unless they confirm.
    this.$dialog
      .confirm('Exit Weigh In?', options)
      .then(function () {
        localStorage.id = '';
        console.log('LEAVING EVENT');
        next();
      })
      .catch(function () {
        console.log('STAYING IN THE EVENT');
      });
  },
  computed: {
    activeUser() {
      return localStorage.activeUser;
    },
    eventID() {
      return this.$store.getters['getEventID'];
    },
    eventDate() {
      return beautifyDateForScoreboard(this.$store.getters['getEventDate']);
    },
    bodyOfWater() {
      return this.$store.getters['getBodyOfWater'];
    },
    allWeighInTeams() {
      return this.$store.getters['getAllWeighInTeams'];
    },
    weighInTeamExists() {
      if ( this.allWeighInTeams.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    openAddTeamModal() {
      this.selectedTeam = '';
      this.$bvModal.show('update-weigh-in-team-modal');
    },
    weighInTeamClicked(team) {
      if (localStorage.activeUser == 'ADMIN') {
        this.selectedTeam = team;
        this.$bvModal.show('update-weigh-in-team-modal');
      }
    }
  },
};
</script>
<style>
.weighIn-eventInfoText {
  color: var(--primary-font-color);
  font-size: 1.5em;
  text-align: left;
  margin: 0 0 0 0;
}

.weighIn-col {
  padding: 0px 0px 0px 0px;
}

#standing {
  border: 1px solid var(--color30);
  /* background-color: var(--color60); */
  background-color: var(--supplemental-color);
}

.weighIn-teamPlace {
  font-family: var(--leaderboard-font);
  color: var(--color10);
  font-size: 2em;
  font-weight:bolder;
  margin: 0 0 0 0;
  /* padding: 0 0 0 0; */
}

.weighIn-teamName {
  font-family: var(--leaderboard-font);
  font-size: 1.15em;
  font-weight:bold;
  color: var(--primary-font-color);
  margin: 0;
  padding: 0;
}

.weighIn-bigFishOwner {
  font-family: var(--leaderboard-font);
  font-size: 1em;
  font-weight: bold;
  color: var(--color30);
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.weighIn-bigFish {
  font-family: var(--leaderboard-font);
  font-size: 1em;
  font-weight:400;
  color: var(--primary-font-color);
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.weighIn-totalWeight {
  font-family: var(--leaderboard-font);
  font-size: 1.25em;
  font-weight: bold;
  color: var(--primary-font-color);
  margin: 0px 10px 0px 0px;
}

.weighIn-fishCount {
  font-family: var(--leaderboard-font);
  font-size: 1em;
  font-weight: 400;
  color: var(--primary-font-color);
  margin: 0;
  padding: 0;
}

.weighIn-please-enter-teams {
  font-family: var(--leaderboard-font);
  color: var(--color10);
}


/* SHOW CATCHES COMPONENT =================== */
.showCatches-table {
  table-layout: fixed;
  width: '50%';
}

.showCatches-tableFont {
  color: #000000;
}
</style>