<template>
  <div>
    <top-nav></top-nav>
    <h2 class="pageHeaderText mt-1 mb-2">CASTCLASH TOURNAMENTS</h2>

    <!-- HOW CAN IT GET BETTER? -->
    <div class="lightSection">
      <b-container>
        <h2 class="home-sectionTitle">Creating a Tournament With Your Friends</h2>


        <h4 class="home-sectionText">
            The organizer of the tournament will be responsible for creating the event in CastClash.
        </h4>


        <h4 class="home-sectionText">
            <strong>Step 1.</strong> Go to the <strong>TOURNAMENTS</strong> screen.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 2.</strong> Enter the date that you'd like to have your tournament.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 3.</strong> Select how you'd like to score your tournament...
        </h4>

        <ul class="text-left">
          <li class="h4"><strong>Best 5 Fish</strong> - Standard bass fishing tournament rules. Only the largest 5 fish entered by each team will be their total weight.</li>
          <li class="h4"><strong>Total Weight of All Fish</strong> - Similar to MLF scoring. The total weight will include ALL fish caught and entered by each team.</li>
        </ul>

        <h4 class="home-sectionText">
            <strong>Step 4.</strong> Enter the name of the Lake or River where your tournament will take place.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 5.</strong> Enter the names of the anglers or their team names one at a time. You must have at least two teams for a valid tournament.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 6.</strong> Once all fields have been correctly filled out, press the CREATE button to generate the tournament in CastClash.
        </h4>
        <b-img class="T0" :src="require('../images/tutorials/T0.jpg')" fluid alt="Responsive image"></b-img>

        <h4 class="home-sectionText">
          <strong>Step 7.</strong> You will now recieve a confirmation that your tournament was created. You will also recieve an Event Code to share with each person in the tournament. (see image)
        </h4>
        <b-img class="T1" :src="require('../images/tutorials/T1.jpg')" fluid alt="Responsive image"></b-img>

        <h4 class="home-sectionText">
            <strong>Step 8.</strong> As the organizer of the tournament, your job is done. Just make sure that you gave each person the <strong>Event Code</strong>.
        </h4>

        <h4 class="home-sectionText">
            <strong>Step 9.</strong> On the day of the tournament, prior to getting started, have each team open CastClash on their mobile device and click <strong>JOIN</strong> in the navigation. Here they will enter the Event Code and press the <strong>JOIN</strong> button.
        </h4>
        <b-img class="T2" :src="require('../images/tutorials/T2.jpg')" fluid alt="Responsive image"></b-img>
  
        <h4 class="home-sectionText">
            <strong>Step 10.</strong> Lastly, each team will click on their name on the list to enter the tournament.
        </h4>
        <b-img class="T3" :src="require('../images/tutorials/T3.jpg')" fluid alt="Responsive image"></b-img>


        <h4 class="home-sectionText">
            <strong>Step 10.</strong> You are now inside your own scoreboard for your event. Each team can enter their fish catches and the scoreboard will update LIVE! Enjoy!
        </h4>
        <b-img class="T4" :src="require('../images/tutorials/T4.jpg')" fluid alt="Responsive image"></b-img>

      </b-container>
    </div>





  </div>

</template>
<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style>
.T0 {
  height: 50%;
  width: 50%;
}
.T1 {
  height: 50%;
  width: 50%;
}
.T2 {
  height: 50%;
  width: 50%;
}
.T3 {
  height: 50%;
  width: 50%;
}
.T4 {
  height: 50%;
  width: 50%;
}
</style>